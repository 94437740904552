import React from "react"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import { FormHelperText } from "@mui/material"

type PhoneInputProps = {
  value: string
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleClearMobileNumber?: () => void
  error?: boolean
  handlePressEnter: () => void
}

const PhoneInput = ({
  value,
  onChangeHandler,
  handleClearMobileNumber,
  error,
  handlePressEnter,
}: PhoneInputProps) => {
  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (["e", "E", "+", "-"].includes(evt.key)) {
      evt.preventDefault()
    }
    if (evt.key === "Enter") {
      handlePressEnter()
    }
  }

  return (
    <FormControl variant="outlined">
      <OutlinedInput
        sx={{ height: "44px" }}
        type="number"
        placeholder="9999 9999 99"
        autoComplete="off"
        value={value}
        onChange={onChangeHandler}
        startAdornment={<InputAdornment position="start">+91</InputAdornment>}
        endAdornment={
          handleClearMobileNumber ? (
            <InputAdornment position="end">
              <IconButton onClick={handleClearMobileNumber} edge="end">
                <CancelOutlinedIcon color="error" />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        onKeyDown={handleKeyDown}
        error={error}
      />
      {error && <FormHelperText error>Phone number is invalid</FormHelperText>}
    </FormControl>
  )
}

export default PhoneInput
