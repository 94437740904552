import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import { USER_MODULE } from "../constants/modules"
import { User } from "../models/users.model"

type UserState = {
  userData: User
}

const initialState: UserState = {
  userData: {
    accessToken: "",
    userId: "",
    mobile: "",
    firstName: "",
    LastName: "",
  },
}

export const userSlice = createSlice({
  name: USER_MODULE,
  initialState,
  reducers: {
    setUserData: (state, { payload }: { payload: { userData: User } }) => {
      state.userData = payload.userData
    },
  },
})

export const { setUserData } = userSlice.actions

export const selectUser = (state: RootState) => state[USER_MODULE]

export default userSlice.reducer
