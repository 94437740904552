import { Alert, Snackbar } from "@mui/material"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import { hideToast, selectApp } from "../../slices/appSlice"

export function Toast(): JSX.Element {
  const { toast } = useAppSelector(selectApp)
  const dispatch = useAppDispatch()
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={toast.open}
      autoHideDuration={3000}
      onClose={() => dispatch(hideToast())}
    >
      <Alert
        onClose={() => dispatch(hideToast())}
        severity={
          toast.toastType === "success"
            ? "success"
            : toast.toastType === "error"
            ? "error"
            : toast.toastType === "info"
            ? "info"
            : "warning"
        }
        elevation={6}
        variant="filled"
      >
        {toast.msg}
      </Alert>
    </Snackbar>
  )
}

export default Toast
