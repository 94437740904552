export class User {
  accessToken: string = ""
  userId: string = ""
  mobile: string = ""
  firstName: string = ""
  LastName: string = ""

  constructor(user?: any) {
    if (user) {
      this.accessToken = user?.access_token
      this.userId = user?.user?.id
      this.mobile = user?.user?.mobile
      this.firstName = user?.user?.first_name
      this.LastName = user?.user?.last_name
    }
  }
}

export class UserContact {
  userId: string = ""
  mobile: string = ""
  nickname: string = ""

  constructor(contacts?: any) {
    if (contacts) {
      this.userId = contacts?.id
      this.mobile = contacts?.mobile
      this.nickname = contacts?.nickname
    }
  }
}
