import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "../utils/baseQuery"
import { CENTERS_MODULE } from "../constants/modules"
import { CENTERS_API, CENTER_BY_ID_API } from "../constants/apis"
import { Center } from "../models/centers.model"
import { computeRoute } from "../utils/compute-route"

interface GetCenterByIdParams {
  centerId: string
}
interface CenterData {
  id: string
  name: string
  google_maps_url: string
  day_pass_price: string
  images: string
  day_pass_discounts_percentage: {
    10: {
      value: number
    }
  }
  google_maps_thumbnail_url: string
}

interface GetCentersAPIResponse {
  data: CenterData[]
}
interface GetCenterByIdAPIResponse {
  data: CenterData
}

export const centersApi = createApi({
  baseQuery: baseQuery,
  tagTypes: [CENTERS_MODULE],
  reducerPath: `/api`,
  endpoints: (builder) => ({
    getCenters: builder.query<Center[], any>({
      query: () => ({
        url: `${CENTERS_API}`,
        method: "GET",
        params: {
          limit: 1000,
          is_day_pass_enabled: true,
        },
      }),
      transformResponse: (response: GetCentersAPIResponse) =>
        response?.data?.map((data) => ({
          ...new Center(data),
        })),
    }),
    getCenterById: builder.query<Center, GetCenterByIdParams>({
      query: ({ centerId }) => ({
        url: computeRoute(CENTER_BY_ID_API, { centerId }),
        method: "GET",
      }),
      transformResponse: (response: GetCenterByIdAPIResponse) => ({
        ...new Center(response?.data),
      }),
    }),
  }),
})

export const { useGetCentersQuery, useGetCenterByIdQuery } = centersApi
