import { Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { ROOT } from "../../constants/path"
import { Wrapper } from "./styles"
import { Button } from "../Button"

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <Wrapper>
      <Typography variant="h1">404</Typography>
      <Typography variant="h4">
        We can't seem to find a page you're looking for.
      </Typography>
      <Button
        variant="contained"
        onClick={() => navigate(ROOT)}
        sx={{ mt: 5 }}
        width="max-content"
      >
        Back to Home
      </Button>
    </Wrapper>
  )
}

export default NotFound
