import CallRectangle from "../../assets/CallRectangle.svg"
import { CallImage, Logo, StyledAppBar } from "./styles"
import BhiveLogo from "../../assets/BhiveLogo.svg"
import { CENTERS } from "../../constants/path"

const Header = () => {
  return (
    <StyledAppBar elevation={0}>
      <Logo
        src={BhiveLogo}
        alt="BhiveLogo"
        onClick={() => window.open(CENTERS, "_self")}
      />

      <a href="tel:+919538677774">
        <CallImage src={CallRectangle} alt="call-icon" />
      </a>
    </StyledAppBar>
  )
}

export default Header
