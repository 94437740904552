import { styled, Button as MUIButton } from "@mui/material"
import theme from "../theme"

type ButtonProps = {
  width?: string
  height?: string
}

export const Button = styled(MUIButton)<ButtonProps>`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "44px")};
  font-weight: 600;
  font-size: 16px;
  border: 1px solid ${theme.palette.primary.dark};
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px ${theme.palette.secondary.main};
  text-transform: none;

  svg {
    margin-right: 8px;
  }

  :disabled {
    border: 1px solid ${theme.palette.background.default};
  }
`
