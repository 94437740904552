import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import { BOOKING_MODULE } from "../constants/modules"
import { DayType } from "../constants/enums"

type BookingState = {
  steps: string[]
  mobileNumber: string
  day: string
  numberOfPasses: number
  mobileNumbers: string[]
}

const initialState: BookingState = {
  steps: [
    "Mobile No.",
    "Booking Preferences",
    "Add Members",
    "Booking Summary",
  ],
  mobileNumber: "",
  day: DayType.TODAY,
  numberOfPasses: 1,
  mobileNumbers: [""],
}

export const bookingSlice = createSlice({
  name: BOOKING_MODULE,
  initialState,
  reducers: {
    setMobileNumber: (state, { payload }: { payload: { value: string } }) => {
      state.mobileNumber = payload.value
    },
    setSelectedDay: (state, { payload }: { payload: { day: string } }) => {
      state.day = payload.day
    },
    incrementNumberOfPasses: (state) => {
      state.numberOfPasses = state.numberOfPasses + 1
      state.mobileNumbers = Array(state.numberOfPasses).fill("")
    },
    decrementNumberOfPasses: (state) => {
      state.numberOfPasses = state.numberOfPasses - 1
      state.mobileNumbers = Array(state.numberOfPasses).fill("")
    },
    setMobileNumbers(state, action) {
      state.mobileNumbers = action.payload
    },
    clearMobileNumber(state, action) {
      const { index } = action.payload
      state.mobileNumbers[index] = ""
    },
    resetBookingState: (state) => {
      state.day = DayType.TODAY
      state.numberOfPasses = 1
      state.mobileNumbers = [""]
    },
  },
})

export const {
  resetBookingState,
  setMobileNumber,
  setSelectedDay,
  incrementNumberOfPasses,
  decrementNumberOfPasses,
  setMobileNumbers,
  clearMobileNumber,
} = bookingSlice.actions

export const selectBooking = (state: RootState) => state.booking

export default bookingSlice.reducer
