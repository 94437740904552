import { styled } from "@mui/material"
import theme from "../../theme"

export const Wrapper = styled("div")`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.palette.primary.contrastText};
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: ${theme.palette.background.paper};

  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    height: 41px;
    font-size: 12px;
  }
`
