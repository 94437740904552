import {
  Wrapper,
  HexagonIcon,
  DownloadAppButtons,
  DownloadAppText,
  SubHeading,
  Heading,
} from "./styles"
import SuccessHexaIcon from "../../../assets/SuccessHexaIcon.svg"
import AppStoreIcon from "../../../assets/AppStoreIcon.svg"
import PlaystoreIcon from "../../../assets/PlaystoreIcon.svg"
import { APP_STORE_URL, PLAY_STORE_URL } from "../../../constants/urls"
import { useLocation, useNavigate } from "react-router-dom"
import { CENTERS } from "../../../constants/path"
import { useEffect } from "react"

const BookingSuccess = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!state?.bookingCodes) navigate(CENTERS)
  }, [state, navigate])

  return (
    <Wrapper>
      <HexagonIcon src={SuccessHexaIcon} alt="" />
      <Heading>
        Success! Your bookings are confirmed and your Booking IDs are:{" "}
        {state?.bookingCodes ? state?.bookingCodes : ""}
      </Heading>
      <SubHeading>
        Your passes will soon arrive on your BHIVE app's dashboard. Click below
        buttons to download the app now!
      </SubHeading>
      <DownloadAppText>Download Our App Now</DownloadAppText>
      <DownloadAppButtons>
        <img
          src={PlaystoreIcon}
          alt="play-store-icon"
          height="45px"
          onClick={() => window.open(PLAY_STORE_URL)}
        />
        <img
          src={AppStoreIcon}
          alt="app-store-icon"
          height="45px"
          onClick={() => window.open(APP_STORE_URL)}
        />
      </DownloadAppButtons>
    </Wrapper>
  )
}

export default BookingSuccess
