import { Typography, styled } from "@mui/material"
import SuccessBackground from "../../../assets/SuccessBackground.svg"
import theme from "../../../theme"

export const Wrapper = styled("div")`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 104px 10% 80px;
  padding: 92px 10% 32px;
  border: 1px solid ${theme.palette.neutrals[4]};
  text-align: center;

  background-color: ${theme.palette.background.paper};
  background: url(${SuccessBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  box-shadow: 0px 1px 8px 0px ${theme.palette.neutrals[4]};

  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    margin-block: 20px;
  }
`

export const HexagonIcon = styled("img")`
  width: 312px;
  height: 312px;
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    width: 188px;
    height: 188px;
  }
`

export const Heading = styled(Typography)`
  margin-top: 42px;
  font-size: 24px;
  font-weight: 600;
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    font-size: 16px;
    margin-top: 24px;
  }
`

export const SubHeading = styled(Typography)`
  margin-top: 18px;
  font-size: 20px;
  font-weight: 500;
  color: ${theme.palette.neutrals[2]};
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    font-size: 14px;
    margin-top: 12px;
  }
`

export const DownloadAppText = styled(Typography)`
  margin-top: 102px;
  font-size: 14px;
  font-weight: 600;
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    margin-top: 74px;
    font-size: 10px;
  }
`

export const DownloadAppButtons = styled("div")`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  cursor: pointer;
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    img {
      height: 40px;
    }
  }
`
