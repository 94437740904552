import {
  StepperCardFooter,
  StepperCardHeader,
  StepperCardBody,
  MobileNumberLabel,
  VerifiedUserName,
  DateButton,
  NumberOfPassesLabel,
  PassesButtonWrapper,
  InfoDiv,
  StyledIconButton,
  NumberOfPassesText,
} from "./styles"
import { Stack, Typography } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import CalenderPlusIcon from "../../../assets/CalenderPlusIcon.svg"
import VerifiedUserIcon from "../../../assets/VerifiedUserIcon.svg"
import DateSelectionIcon from "../../../assets/DateSelectionIcon.svg"
import { Button } from "../../../components/Button"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  decrementNumberOfPasses,
  incrementNumberOfPasses,
  selectBooking,
  setSelectedDay,
} from "../../../slices/bookingSlice"
import { DayType, DaypassType, URLParams } from "../../../constants/enums"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import dayjs from "dayjs"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CENTERS } from "../../../constants/path"
import { selectUser } from "../../../slices/userSlice"

export const BookingPreferences = () => {
  const dispatch = useAppDispatch()
  const { userData } = useAppSelector(selectUser)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const passType = searchParams.get(URLParams.PASS_TYPE)
  const { day, numberOfPasses } = useAppSelector(selectBooking)

  const handleDecrement = () => {
    dispatch(decrementNumberOfPasses())
  }

  const handleIncrement = () => {
    dispatch(incrementNumberOfPasses())
  }

  return (
    <>
      <StepperCardHeader>
        <img src={CalenderPlusIcon} height="48px" alt="" />
        <VerifiedUserName>
          <img src={VerifiedUserIcon} height="24px" alt="" />
          <Typography>
            {userData?.firstName && userData?.LastName
              ? `${userData?.firstName} ${userData?.LastName}`
              : userData?.mobile}
          </Typography>
        </VerifiedUserName>
      </StepperCardHeader>
      <StepperCardBody>
        {passType === DaypassType.BULKDAYPASS ? (
          <MobileNumberLabel>
            Your booking will be valid for 30 days from{" "}
            <span>{dayjs().format("D MMM YYYY")}</span>
          </MobileNumberLabel>
        ) : (
          <>
            <MobileNumberLabel>
              Please enter the preferred date
            </MobileNumberLabel>
            <Stack direction="row" gap="14px">
              <DateButton
                className={`${day === DayType.TODAY ? "selected" : ""}`}
                onClick={() => dispatch(setSelectedDay({ day: DayType.TODAY }))}
              >
                <img src={DateSelectionIcon} height="15px" alt="" />
                Today, <span>{dayjs().format("D MMM")}</span>
              </DateButton>
              <DateButton
                className={`${day === DayType.TOMMORROW ? "selected" : ""}`}
                onClick={() =>
                  dispatch(setSelectedDay({ day: DayType.TOMMORROW }))
                }
              >
                <img src={DateSelectionIcon} height="15.5px" alt="" />
                Tomorrow, <span>{dayjs().add(1, "day").format("D MMM")}</span>
              </DateButton>
            </Stack>
          </>
        )}

        <NumberOfPassesLabel>Select Number of Passes</NumberOfPassesLabel>
        <Stack direction="row" gap="24px" alignItems="center">
          <PassesButtonWrapper>
            <StyledIconButton
              onClick={handleDecrement}
              disabled={numberOfPasses === 1}
            >
              <RemoveIcon />
            </StyledIconButton>
            <NumberOfPassesText>{numberOfPasses}</NumberOfPassesText>
            <StyledIconButton
              onClick={handleIncrement}
              disabled={numberOfPasses === 4}
            >
              <AddIcon />
            </StyledIconButton>
          </PassesButtonWrapper>
          <InfoDiv>4 Members Max</InfoDiv>
        </Stack>
      </StepperCardBody>
      <StepperCardFooter>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate(CENTERS, { replace: true })}
          sx={{ mr: 1 }}
        >
          <ArrowBackIcon />
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setSearchParams((params) => {
              params.set(URLParams.ACTIVE_STATE, "2")
              return params
            })
          }}
          disabled={day === ""}
        >
          Next
        </Button>
      </StepperCardFooter>
    </>
  )
}

export default BookingPreferences
