import { styled } from "@mui/material"
import theme from "../../theme"

export const ParentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: inherit;
  position: relative;
  overflow: auto;
  background: ${theme.palette.secondary.main};
`

export const MainContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 90px;
  width: 100%;
  max-width: 1440px;
  flex: 1;
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    margin: 0;
  }
`

export const HoverEffect = `
  &:hover {
    cursor: pointer;
    box-shadow: 0px 3px 6px 0px ${theme.palette.neutrals[3]};
  }
`
