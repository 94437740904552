import {
  BOOKING_DAYPASS_PAYMENT_FAILED_API,
  BOOKING_DAYPASS_PAYMENT_SUCCESS_API,
  BOOKING_GIFT_DAYPASS_API,
} from "../constants/apis"
import { PaymentOrderDetails } from "../models/booking.model"
import { centersApi } from "./centersApi"

interface GiftDaypassParams {
  recipients: { mobile: string }[]
  passType: number
  centerId: string
  bookingDate: string
}

interface GiftDaypassAPIResponse {
  data: {
    payment_order_details: {
      amount: number
      currency: string
      id: string
    }
    user_details: {
      email: string
      mobile: string
      first_name: string
      last_name: string
    }
    day_pass_bookings: {
      booking_code: string
    }[]
  }
}

interface PaymentSuccessParams {
  razorpayOrderId: string
  razorpayPaymentId: string
  razorpaySignature: string
}

interface PaymentFailedParams {
  razorpayOrderId: string
  reason: string
}

export const bookingApi = centersApi.injectEndpoints({
  endpoints: (builder) => ({
    giftDaypass: builder.mutation<PaymentOrderDetails, GiftDaypassParams>({
      query: ({ recipients, passType, centerId, bookingDate }) => ({
        url: BOOKING_GIFT_DAYPASS_API,
        method: "POST",
        body: {
          recipients,
          pass_count: passType,
          center_id: centerId,
          booking_date: bookingDate,
          booking_ref: "web",
        },
      }),
      transformResponse: (response: GiftDaypassAPIResponse) => ({
        ...new PaymentOrderDetails(response?.data),
      }),
    }),
    paymentSuccess: builder.mutation<any, PaymentSuccessParams>({
      query: ({ razorpayOrderId, razorpayPaymentId, razorpaySignature }) => ({
        url: BOOKING_DAYPASS_PAYMENT_SUCCESS_API,
        method: "PUT",
        body: {
          order_id: razorpayOrderId,
          payment_id: razorpayPaymentId,
          razorpay_signature: razorpaySignature,
        },
      }),
    }),
    paymentFailed: builder.mutation<any, PaymentFailedParams>({
      query: ({ razorpayOrderId, reason }) => ({
        url: BOOKING_DAYPASS_PAYMENT_FAILED_API,
        method: "PUT",
        body: {
          order_id: razorpayOrderId,
          reason,
        },
      }),
    }),
  }),
})

export const {
  useGiftDaypassMutation,
  usePaymentSuccessMutation,
  usePaymentFailedMutation,
} = bookingApi
