export const calculateDaypassValues = (
  singleDaypassPrice: number,
  discountPercentage: number,
  numberOfPasses: number,
  tax: number,
  bulkDaypass: boolean,
): {
  membersAmount: number
  discountAmount: number
  taxAmount: number
  totalAmount: number
  totalAmountWithoutTax: number
} => {
  // Multiply singleDaypassPrice by 10 if bulkDaypass is true
  const daypassPrice = bulkDaypass
    ? singleDaypassPrice * 10
    : singleDaypassPrice

  // Calculate members amount
  let membersAmount = daypassPrice * numberOfPasses

  // Calculate discount amount
  let discountAmount = (membersAmount * discountPercentage) / 100

  // Calculate total amount without tax
  let totalAmountWithoutTax = membersAmount - discountAmount

  // Calculate tax amount and total amount based on the bulkDaypass
  let taxAmount = 0
  let totalAmount = 0
  if (bulkDaypass) {
    taxAmount = (membersAmount - discountAmount) * (tax / 100)
    totalAmount = membersAmount - discountAmount + taxAmount
  } else {
    taxAmount = membersAmount * (tax / 100)
    totalAmount = membersAmount + taxAmount
  }

  // Round off all values to two decimal places
  membersAmount = Math.floor(parseFloat(membersAmount.toFixed(2)))
  discountAmount = Math.floor(parseFloat(discountAmount.toFixed(2)))
  taxAmount = Math.floor(parseFloat(taxAmount.toFixed(2)))
  totalAmount = Math.floor(parseFloat(totalAmount.toFixed(2)))
  totalAmountWithoutTax = Math.floor(
    parseFloat(totalAmountWithoutTax.toFixed(2)),
  )

  // Return output values
  return {
    membersAmount,
    discountAmount,
    taxAmount,
    totalAmount,
    totalAmountWithoutTax,
  }
}
