export enum DaypassType {
  DAYPASS = "daypass",
  BULKDAYPASS = "bulkDaypass",
}

export enum DayType {
  TODAY = "today",
  TOMMORROW = "tomorrow",
}

export enum URLParams {
  ACTIVE_STATE = "activeState",
  PASS_TYPE = "passType",
}
