import { Stepper, Typography, styled } from "@mui/material"
import theme from "../../theme"

export const StepperWrapper = styled("div")`
  width: 100%;
  overflow: hidden;
  .css-3qwhp6-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: ${theme.palette.success.main};
  }
`

export const StyledStepper = styled(Stepper)`
  .MuiSvgIcon-root {
    width: 32px;
    height: 32px;
  }
  .MuiSvgIcon-root.Mui-active {
    padding: 3px;
    border-radius: 50%;
    border: 2px solid ${theme.palette.primary.main};
  }
  text {
    display: none;
  }
  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    .MuiSvgIcon-root {
      width: 12px;
      height: 12px;
    }
    .MuiSvgIcon-root.Mui-active {
      padding: 1px;
      border-radius: 50%;
      border: 1px solid ${theme.palette.primary.main};
    }
  }
`

export const StepperText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    font-size: 12px;
    font-weight: 500;
  }
`
