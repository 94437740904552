import { Stack, styled } from "@mui/material"
import theme from "../../theme"

export const Wrapper = styled(Stack)`
  width: 80%;
  align-items: center;
  gap: 20px;
  padding: 50px 5%;
  margin-block: 50px;
  text-align: center;
  border-radius: 1rem;
  background: ${theme.palette.background.paper};
  box-shadow: 0px 1px 8px 0px ${theme.palette.neutrals[4]};
`
