import { useState } from "react"
import {
  StepperCardFooter,
  StepperCardHeader,
  StepperCardBody,
  MobileNumberLabel,
} from "./styles"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import MobileIcon from "../../../assets/MobileIcon.svg"
import { Button } from "../../../components/Button"
import OTPModal from "../../../components/OTPModal/OTPModal"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectBooking, setMobileNumber } from "../../../slices/bookingSlice"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CENTERS } from "../../../constants/path"
import { useGetOTPMutation, useUserLoginMutation } from "../../../apis/userApi"
import PhoneInput from "../../../components/PhoneInput/PhoneInput"
import { URLParams } from "../../../constants/enums"
import { selectUser, setUserData } from "../../../slices/userSlice"

const MobileNumber = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const { userData } = useAppSelector(selectUser)
  const { mobileNumber } = useAppSelector(selectBooking)
  const [otpModalVisibility, setOtpModalVisibility] = useState(false)
  const [error, setError] = useState(false)
  const [getOTP] = useGetOTPMutation()
  const [userLogin, { data, isSuccess: isOTPConfirmed }] =
    useUserLoginMutation()

  if (userData.accessToken === "" && data?.accessToken) {
    dispatch(setUserData({ userData: data }))
  }

  if (isOTPConfirmed) {
    setSearchParams((params) => {
      params.set(URLParams.ACTIVE_STATE, "1")
      return params
    })
  }

  const handleMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    if (value.length <= 10) {
      dispatch(setMobileNumber({ value }))
    }
    if (value.length === 10) {
      setError(false)
    }
  }

  const generateOTPHandler = () => {
    if (mobileNumber.length === 10) {
      getOTP({ mobileNumber })
      setOtpModalVisibility(true)
    } else {
      setError(true)
    }
  }

  return (
    <>
      <StepperCardHeader>
        <img src={MobileIcon} height="48px" alt="" />
      </StepperCardHeader>
      <StepperCardBody>
        <MobileNumberLabel>Please enter your mobile number</MobileNumberLabel>
        <PhoneInput
          value={mobileNumber}
          onChangeHandler={handleMobileNumberChange}
          error={error}
          handlePressEnter={generateOTPHandler}
        />
      </StepperCardBody>
      <StepperCardFooter>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate(CENTERS, { replace: true })}
          sx={{ mr: 1 }}
        >
          <ArrowBackIcon />
          Back
        </Button>
        <Button onClick={generateOTPHandler} variant="contained">
          Get OTP
        </Button>
      </StepperCardFooter>
      {otpModalVisibility && (
        <OTPModal
          open={otpModalVisibility}
          onClose={setOtpModalVisibility}
          resendOTP={getOTP}
          onConfirm={userLogin}
          mobileNumber={mobileNumber}
        />
      )}
    </>
  )
}

export default MobileNumber
