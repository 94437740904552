import { Divider, Stack, Typography } from "@mui/material"
import {
  BookingDetailsRow,
  BookingDetailsRowItem,
  BookingText,
  PriceBeakupRow,
  PriceBeakupSubText,
  PriceBeakupText,
  StepperCardBody,
  StepperCardFooter,
  StepperCardHeader,
  TotalRowText,
  ValidTextDiv,
  VerifiedUserName,
  VerticalDivider,
} from "./styles"
import VerifiedUserIcon from "../../../assets/VerifiedUserIcon.svg"
import BookingSummaryIcon from "../../../assets/BookingSummaryIcon.svg"
import { Button } from "../../../components/Button"
import { selectBooking } from "../../../slices/bookingSlice"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useAppSelector } from "../../../app/hooks"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined"
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined"
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined"
import { DayType, DaypassType, URLParams } from "../../../constants/enums"
import dayjs from "dayjs"
import { calculateDaypassValues } from "../../../utils/calculateDaypassValues"
import {
  useGiftDaypassMutation,
  usePaymentFailedMutation,
  usePaymentSuccessMutation,
} from "../../../apis/bookingApi"
import theme from "../../../theme"
import { BOOKING_SUCCESS } from "../../../constants/path"
import { selectUser } from "../../../slices/userSlice"

type BookingSummaryProps = {
  daypassPrice: number | undefined
  discountsPercentage: number | undefined
}

const BookingSummary = ({
  daypassPrice,
  discountsPercentage,
}: BookingSummaryProps) => {
  const navigate = useNavigate()
  const { userData } = useAppSelector(selectUser)
  const { centerId = "" } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const passType = searchParams.get(URLParams.PASS_TYPE)

  const { day, numberOfPasses, mobileNumbers, mobileNumber } =
    useAppSelector(selectBooking)

  const {
    membersAmount = 0,
    discountAmount = 0,
    taxAmount = 0,
    totalAmount = 0,
  } = daypassPrice && discountsPercentage
    ? calculateDaypassValues(
        daypassPrice,
        discountsPercentage,
        numberOfPasses,
        18,
        passType === DaypassType.BULKDAYPASS ? true : false,
      )
    : {}

  const [giftDaypassTrigger] = useGiftDaypassMutation()
  const [paymentSuccessTrigger] = usePaymentSuccessMutation()
  const [paymentFailedTrigger] = usePaymentFailedMutation()

  const paynowHandler = async () => {
    try {
      const paymentOrderDetails = await giftDaypassTrigger({
        recipients:
          numberOfPasses === 1
            ? [{ mobile: mobileNumber }]
            : mobileNumbers.map((num) => ({ mobile: num })),
        passType: passType === DaypassType.BULKDAYPASS ? 10 : 1,
        centerId,
        bookingDate:
          day === DayType.TODAY
            ? dayjs().format("YYYY-MM-DD")
            : dayjs().add(1, "day").format("YYYY-MM-DD"),
      }).unwrap()

      if (paymentOrderDetails?.id) {
        const options = {
          key: import.meta.env.PROD
            ? import.meta.env.RAZORPAY_SECRET
            : import.meta.env.RAZORPAY_TEST_KEY,
          order_id: paymentOrderDetails?.id,
          amount: paymentOrderDetails?.amount,
          currency: paymentOrderDetails?.currency,
          name: "BHIVE Workspace",
          description:
            passType === DaypassType.BULKDAYPASS
              ? "Bulk Daypass Booking"
              : "Daypass Booking",
          image: import.meta.env.RAZORPAY_IMAGE_URL,
          handler: async function (response: {
            razorpay_payment_id: string
            razorpay_order_id: string
            razorpay_signature: string
          }) {
            try {
              await paymentSuccessTrigger({
                razorpayOrderId: response.razorpay_order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpaySignature: response.razorpay_signature,
              }).unwrap()
              navigate(BOOKING_SUCCESS, {
                replace: true,
                state: {
                  bookingCodes: paymentOrderDetails.bookingCodes.join(","),
                },
              })
            } catch (error) {
              console.error("Error handling payment success:", error)
              // Handle error appropriately
            }
          },
          modal: {
            ondismiss: function () {
              paymentFailedTrigger({
                razorpayOrderId: paymentOrderDetails.id,
                reason: "user decline the payment",
              })
            },
          },
          prefill: {
            email: paymentOrderDetails?.email,
            name: `${paymentOrderDetails?.firstName} ${paymentOrderDetails?.LastName}`,
            contact: paymentOrderDetails?.mobile,
          },
          theme: { color: theme.palette.primary.main || "#ffcf4b" },
        }

        const paymentObject = new (window as any).Razorpay(options)
        paymentObject.open()
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  return (
    <>
      <StepperCardHeader>
        <img src={BookingSummaryIcon} height="48px" alt="" />
        <VerifiedUserName>
          <img src={VerifiedUserIcon} height="24px" alt="" />
          <Typography>
            {userData?.firstName
              ? `${userData?.firstName} ${userData?.LastName}`
              : userData?.mobile}
          </Typography>
        </VerifiedUserName>
      </StepperCardHeader>
      <StepperCardBody>
        <BookingText>Booking Details</BookingText>
        <BookingDetailsRow>
          <BookingDetailsRowItem>
            <CalendarTodayOutlinedIcon />
            {day === DayType.TODAY
              ? dayjs().format("D MMM")
              : dayjs().add(1, "day").format("D MMM")}
          </BookingDetailsRowItem>
          <VerticalDivider />
          <BookingDetailsRowItem>
            <ConfirmationNumberOutlinedIcon />
            {passType === DaypassType.DAYPASS ? "Day Pass" : "Bulk Day Pass"}
          </BookingDetailsRowItem>
          <VerticalDivider />
          <BookingDetailsRowItem>
            <PeopleAltOutlinedIcon />
            {numberOfPasses} Members
          </BookingDetailsRowItem>
        </BookingDetailsRow>
        <Divider />
        <PriceBeakupText>Price Breakup</PriceBeakupText>
        <Stack gap="8px">
          <PriceBeakupRow>
            <PriceBeakupSubText>Base Price / Member</PriceBeakupSubText>
            <PriceBeakupSubText>₹ {daypassPrice}</PriceBeakupSubText>
          </PriceBeakupRow>
          <PriceBeakupRow>
            <PriceBeakupSubText>
              {daypassPrice &&
                `${daypassPrice} ${
                  DaypassType.BULKDAYPASS === passType ? "x 10 days" : ""
                } x ${numberOfPasses} members`}
            </PriceBeakupSubText>
            <PriceBeakupSubText>₹ {membersAmount}</PriceBeakupSubText>
          </PriceBeakupRow>
          {passType === DaypassType.BULKDAYPASS && (
            <PriceBeakupRow>
              <PriceBeakupSubText>
                {discountsPercentage && discountsPercentage}% Discount
              </PriceBeakupSubText>
              <PriceBeakupSubText>₹ {discountAmount}</PriceBeakupSubText>
            </PriceBeakupRow>
          )}
          <PriceBeakupRow>
            <PriceBeakupSubText>18% GST</PriceBeakupSubText>
            <PriceBeakupSubText>₹ {taxAmount}</PriceBeakupSubText>
          </PriceBeakupRow>
          <Divider />
        </Stack>
        <PriceBeakupRow>
          <TotalRowText>
            Total <span>(Including Taxes)</span>
          </TotalRowText>
          <TotalRowText>₹ {totalAmount}</TotalRowText>
        </PriceBeakupRow>
        <ValidTextDiv>
          {passType === DaypassType.BULKDAYPASS
            ? `Valid only for the next 30 days`
            : `Valid only for ${day}`}
        </ValidTextDiv>
      </StepperCardBody>
      <StepperCardFooter>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            setSearchParams((params) => {
              params.set(URLParams.ACTIVE_STATE, "2")
              return params
            })
          }
          sx={{ mr: 1 }}
        >
          <ArrowBackIcon />
          Back
        </Button>
        <Button variant="contained" onClick={paynowHandler}>
          Pay Now
        </Button>
      </StepperCardFooter>
    </>
  )
}

export default BookingSummary
