import { Routes, Route, Navigate } from "react-router-dom"
import { ROOT, CENTERS, BOOKING, BOOKING_SUCCESS } from "../constants/path"
import Centers from "../containers/Centers"
import Booking from "../containers/Booking"
import BookingSuccess from "../containers/Booking/BookingSuccess"
import NotFound from "../components/NotFound/NotFound"

const AppRoutes = () => (
  <Routes>
    <Route path={ROOT} element={<Navigate to={CENTERS} />} />
    <Route path={CENTERS} element={<Centers />} />
    <Route path={BOOKING} element={<Booking />} />
    <Route path={BOOKING_SUCCESS} element={<BookingSuccess />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default AppRoutes
