export const BASE_API = import.meta.env.VITE_BACKEND_URL
export const BASE_API_V1 = `${BASE_API}/api/v1`

export const CENTERS_API = `${BASE_API_V1}/centers`
export const CENTER_BY_ID_API = `${CENTERS_API}/:centerId`

export const USERS_API = `${BASE_API_V1}/users`
export const USERS_LOGIN_API = `${USERS_API}/login`
export const USERS_REQUEST_OTP_API = `${USERS_API}/request_otp`
export const USERS_CONTACTS_BY_ID_API = `${USERS_API}/:userId/contacts`

export const BOOKINGS_API = `${BASE_API_V1}/bookings`
export const BOOKING_GIFT_DAYPASS_API = `${BOOKINGS_API}/gift_day_pass`
export const BOOKING_DAYPASS_API = `${BOOKINGS_API}/day_pass`
export const BOOKING_DAYPASS_PAYMENT_SUCCESS_API = `${BOOKING_DAYPASS_API}/payment/success`
export const BOOKING_DAYPASS_PAYMENT_FAILED_API = `${BOOKING_DAYPASS_API}/payment/failure`
