import AppRoutes from "../../app/routes"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"
import Toast from "../../components/Toast/Toast"
import VersionDailog from "../../components/VersionDialog/VersionDialog"
import { MainContainer, ParentContainer } from "./styled"

export default function Layout() {
  return (
    <ParentContainer>
      <Header />
      <MainContainer>
        <AppRoutes />
        <Toast />
        <VersionDailog />
      </MainContainer>
      <Footer />
    </ParentContainer>
  )
}
