import { centersApi } from "./centersApi"
import {
  USERS_CONTACTS_BY_ID_API,
  USERS_LOGIN_API,
  USERS_REQUEST_OTP_API,
} from "../constants/apis"
import { User, UserContact } from "../models/users.model"
import { computeRoute } from "../utils/compute-route"

export interface GetOTPParams {
  mobileNumber: string
}

export interface UserLoginParams {
  mobileNumber: string
  OTP: string
}
interface UserLoginAPIResponse {
  data: {
    access_token: string
    user: {
      id: string
      first_name: string
      last_name: string
      mobile: string
    }
  }
}

interface GetUserContactsByIdParams {
  userId: string
}
interface GetUserContactsByIdAPIResponse {
  data: {
    user_id: string
    mobile: string
    nickname: string
  }[]
}

export const userApi = centersApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserByOid: builder.query({
      query: (oid: string) => `/users/oid/${oid}`,
      keepUnusedDataFor: 300,
    }),
    getOTP: builder.mutation<any, GetOTPParams>({
      query: ({ mobileNumber }) => ({
        url: USERS_REQUEST_OTP_API,
        method: "POST",
        body: {
          mobile: mobileNumber,
        },
      }),
    }),
    userLogin: builder.mutation<User, UserLoginParams>({
      query: ({ mobileNumber, OTP }) => ({
        url: USERS_LOGIN_API,
        method: "POST",
        body: {
          mobile: mobileNumber,
          otp: OTP,
        },
      }),
      transformResponse: (response: UserLoginAPIResponse) => ({
        ...new User(response?.data),
      }),
    }),
    getUserContactsById: builder.query<
      UserContact[],
      GetUserContactsByIdParams
    >({
      query: ({ userId }) => ({
        url: computeRoute(USERS_CONTACTS_BY_ID_API, { userId }),
        method: "GET",
      }),
      transformResponse: (response: GetUserContactsByIdAPIResponse) =>
        response?.data?.map((data) => ({
          ...new UserContact(data),
        })),
    }),
  }),
})

export const {
  useGetOTPMutation,
  useUserLoginMutation,
  useGetUserContactsByIdQuery,
} = userApi
