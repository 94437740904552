import { Wrapper } from "./styles"

const Footer = () => {
  return (
    <Wrapper>
      <div>© Copyright 2024. Bhive Private Limited</div>
    </Wrapper>
  )
}

export default Footer
