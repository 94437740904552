import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import { APP_MODULE } from "../constants/modules"

type AppState = {
  toast: {
    open: boolean
    toastType: string
    msg: string
  }

  versionDialog: {
    open: boolean
  }
}

const initialState: AppState = {
  toast: {
    open: false,
    toastType: "success",
    msg: "",
  },
  versionDialog: {
    open: false,
  },
}

export const appSlice = createSlice({
  name: APP_MODULE,
  initialState,
  reducers: {
    showToast: (
      state,
      {
        payload,
      }: {
        payload: { toastType: string; msg: string }
      },
    ) => {
      state.toast.open = true
      state.toast.toastType = payload.toastType
      state.toast.msg = payload.msg
    },
    hideToast: (state) => {
      state.toast.open = false
    },
    showDialog: (state) => {
      state.versionDialog.open = true
    },
    hideDialog: (state) => {
      state.versionDialog.open = false
    },
  },
})

export const { showToast, hideToast, showDialog, hideDialog } = appSlice.actions

export const selectApp = (state: RootState) => state[APP_MODULE]

export default appSlice.reducer
