import MobileNumber from "./MobileNumber"
import BookingPreferences from "./BookingPreferences"
import AddMembers from "./AddMembers"
import BookingSummary from "./BookingSummary"
import { StepperCard } from "./styles"
import { useSearchParams } from "react-router-dom"
import { URLParams } from "../../../constants/enums"
import { selectUser } from "../../../slices/userSlice"
import { useAppSelector } from "../../../app/hooks"
import { useEffect } from "react"

type StepperContentProps = {
  daypassPrice: number | undefined
  discountsPercentage: number | undefined
}

const StepperContent = ({
  daypassPrice,
  discountsPercentage,
}: StepperContentProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const activeState = Number(searchParams.get(URLParams.ACTIVE_STATE))
  const {
    userData: { accessToken },
  } = useAppSelector(selectUser)

  // Effect to reset activeState to 0 if no accessToken
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (!accessToken && activeState !== 0) {
      params.set("activeState", "0")
    }
    setSearchParams(params.toString())
  }, [accessToken, activeState, setSearchParams])

  return (
    <StepperCard>
      {accessToken ? (
        <>
          {activeState === 1 && <BookingPreferences />}
          {activeState === 2 && <AddMembers />}
          {activeState === 3 && (
            <BookingSummary
              daypassPrice={daypassPrice}
              discountsPercentage={discountsPercentage}
            ></BookingSummary>
          )}
        </>
      ) : (
        <>{activeState === 0 && <MobileNumber />}</>
      )}
    </StepperCard>
  )
}

export default StepperContent
