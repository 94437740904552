import { Step, StepLabel } from "@mui/material"
import { StepperWrapper, StepperText, StyledStepper } from "./styles"
import { ReactNode } from "react"

type StepperProps = {
  activeStep: number
  steps: string[]
  stepperContent: ReactNode
}

const Stepper = ({ activeStep, steps, stepperContent }: StepperProps) => {
  const visibleSteps =
    activeStep < 2
      ? steps.slice(0, 2)
      : steps.slice(activeStep - 1, activeStep + 1)
  const visibleActiveStep = activeStep === 0 ? 0 : 1
  return (
    <StepperWrapper>
      <StyledStepper activeStep={visibleActiveStep} style={{ width: "100%" }}>
        {visibleSteps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          if (activeStep !== 0) {
            index === 0
              ? (stepProps.completed = true)
              : (stepProps.completed = false)
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <StepperText>{label}</StepperText>
              </StepLabel>
            </Step>
          )
        })}
      </StyledStepper>
      {stepperContent}
    </StepperWrapper>
  )
}

export default Stepper
