import PackageJSON from "../../package.json"
import { store } from "../app/store"

export const prepareHeadersHandler = (headers: any) => {
  const { version } = PackageJSON
  headers.set("web-app-version", version)

  const token = store.getState()?.user?.userData?.accessToken
  if (token) {
    headers.set("Authorization", `Bearer ${token}`)
  }
  return headers
}
